import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import TextFormat, { addBreakLines } from "../utilities/TextFormat";
import GPT from "../utilities/GPT";
import { getPrompt, buttonNames, placeholder, rawTextButton } from "../utilities/TutorData";

import Header from "../components/Header";
import PopUp from "../components/PopUp";
import LanguagePicker from "../components/LanguagePicker";
import WarningAI from "./WarningAI";

const TutorComponent = ({customerData, setCustomerData, updateCollection}) => {
    const {lang, modeSelected} = useParams();
    const [language, setLanguage] = useState(lang);
    const [loadingButton, setLoadingButton] = useState("");
    const [buttonText, setButtonText] = useState(buttonNames[language][modeSelected]);
    const [answer, setAnswer] = useState([]);
    const [toggleRawText, setToggleRawText] = useState(false);
    const [rawText, setRawText] = useState("");
    const [input, setInput] = useState("");
    const [width, setWidth] = useState(window.innerWidth);

    const handleKeypress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        getAnswer();
      }
    };
  
    const showRawText = () => {
      setToggleRawText(!toggleRawText);
    }

    const getLanguage = (lang) => {
      setLanguage(lang);
    }

    const getAnswer = async () => {
      updateCollection(customerData.id, customerData.trial - 1);
      setCustomerData({id: customerData.id, email: customerData.email, access: customerData.access, subscribed: customerData.subscribed, trial: customerData.trial - 1});
      if (customerData.trial - 1 > 0 || customerData.subscribed === true || customerData.access === true) {
        setButtonText("");
        setLoadingButton("button-loader");
        const response = await GPT(getPrompt(language, modeSelected, input));
        setLoadingButton("");
        setButtonText(buttonNames[language][modeSelected]);
        setRawText(response.data.choices[0].message.content);
        setAnswer(TextFormat(response.data.choices[0].message.content));
      } else {
        window.location.reload(true);
      }
    }

    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }
    useEffect(() => {
      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    }, []);

  return (
    <>
      <Header language={language}/>
      <div className="gpt-box">
        <div className="back-button">
          <Link to="/" className="button">
            <> {language === "uk" ? ("Back") : ("Retour")}</>
          </Link>
          <>
            {customerData.trial <= 0 ? (
              <></>
            ) : (
              <div className="trial">
                <> {language === "uk" ? ("Free Spins: ") : ("Tours Gratuit: ")} </>
                {customerData.trial - 1}
              </div>
            )}
            <PopUp mode={modeSelected} trial={customerData.trial > 0} language={language}/>
          </>
        </div>
        <WarningAI language={language}/>
        <div className="gpt-reply">
          {/* Show raw text */}
          <span className={toggleRawText ? "" : "hide"}>
            {rawText}
          </span>
          {/* Show formatted text */}
          <span className={toggleRawText ? "hide" : ""}>
            {answer.map(function(partOfSentence, i){
              return addBreakLines(partOfSentence, i);
            })}
          </span>
        </div>
        <textarea
          tabIndex={0}
          onKeyDown={handleKeypress}
          className="gpt-input"
          rows={width <= 950 ? 2 : 1}
          maxLength="100"
          onChange={(e)=>setInput(e.target.value)}
          placeholder={placeholder[language][modeSelected]}>
        </textarea>
        <div className={modeSelected === "question" ? "hide buttons" : "buttons"}>
          <button onClick={showRawText} className={modeSelected === "question" ? "hide raw" : "raw"}>{toggleRawText ? rawTextButton[1] : rawTextButton[0]}</button>
          <div className="language-gpt-buttons">
            <LanguagePicker language={language} getLanguage={getLanguage}/>
            <button type="submit" onClick={getAnswer} className="button fixed-size"><span className={loadingButton}>{buttonText}</span></button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TutorComponent;
