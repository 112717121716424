import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "../styles/header.scss";

import account from "../icons/account.png"
import home from "../icons/home.png"

const Header = ({language}) => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <header>
      <Link to={"/"} className="shop-title">
        <>
          {width <= 950 ? (
            <img src={home} alt="home"/>
          ) : (
            <>Maths Tutor AI</>
          )}
        </>
      </Link>
      <nav>
        <Link to={"/landing-page/" + language}>Info</Link>
        <Link to={"/pricing/" + language}>Pricing</Link>
        <Link to={"/account"}><img src={account} alt="account"/></Link>
      </nav>
    </header>
  );
};

export default Header;
