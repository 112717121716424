import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, getDocs } from "firebase/firestore"; 

import Header from "../components/Header";
import TutorSelector from "../components/TutorSelector";
import NotSubscribed from "../components/NotSubscribed";
import Welcome from "../components/Welcome";

const Home = () => {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState({});

  const getCollection = async (email) => {
    const querySnapshot = await getDocs(collection(db, "customers"));
    querySnapshot.forEach((doc) => {
      if (email === doc.data().email) {
        setCustomerData(doc.data());
        setLoading(false);
      }
    });
  }

  
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
        getCollection(user.email);
      } else {
        setAuthUser(null);
        setLoading(false);
      }
    });

    return () => {
      listen();
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="loading loader"></div>
      ) : (
        <div>
          {authUser ? (
            <>
              <Header language={customerData.language}/>
              {customerData.subscribed || customerData.access || customerData.trial > 0
                ? (<TutorSelector lang={customerData.language}/>)
                : (<NotSubscribed lang={customerData.language}/>)}
            </>
          ):(
            <Welcome/>
          )}
        </div>
      )}
    </>
  );
};

export default Home;
