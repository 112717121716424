

export const getPrompt = (language, answerLength, author, bookTitle, chapters) => {
  console.log(chapters, chapters.length);
    if (language === "uk") {
      if (answerLength === "small") {
        return `write a short summary of the book ${bookTitle} by ${author}`;
      } else if (answerLength === "medium") {
        return `write a summary of all ${chapters.length > 0 ? chapters : "the"} chapters in the book ${bookTitle} by ${author}, broken down by chapter`;
      } else if (answerLength === "large") {
        return `Act as an English teacher, write a summary of all ${chapters.length > 0 ? chapters : "the"} chapters in the book ${bookTitle} by ${author}, broken down by chapter with an analysis.`;
      }
    } else {
      if (answerLength === "small") {
        return `écrire un résumé court du livre ${bookTitle} écrit par ${author}`;
      } else if (answerLength === "medium") {
        return `écrire un résumé de ${chapters.length > 0 ? `les ${chapters}` : "chaque"} chapitre du livre ${bookTitle} écrit par ${author}`;
      } else if (answerLength === "large") {
        return `écrire un résumé de ${chapters.length > 0 ? `les ${chapters}` : "chaque"} chapitre du livre ${bookTitle} écrit par ${author}. écrit 10 lignes par chapitre.`;
      }
    }
  }

  export const buttonNames = {
    uk: "Get Summary",
    fr: "Go"
  }