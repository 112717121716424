import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { collection, doc, getDocs, updateDoc } from "firebase/firestore";

import Header from "../components/Header";
import LanguagePicker from "../components/LanguagePicker";

import SignOut from "../utilities/SignOut";

// import logout from "../icons/logout.png"

import "../styles/account.scss";

const Account = () => {
  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [customerData, setCustomerData] = useState({
    id: '',
    email: '',
    language: '',
    access: '',
    subscribed: '',
    status: '',
    join_date: '',
  });
  const [language, setLanguage] = useState("uk");
  const [change, setChange] = useState(false);

  const getLanguage = (lang) => {
    if (change) setChange(false);
    setLanguage(lang);
  }

  const getCollection = async (email) => {
    const querySnapshot = await getDocs(collection(db, "customers"));
    querySnapshot.forEach((doc) => {
      if (email === doc.data().email) {
        setCustomerData({id: doc.id, ...doc.data()});
        setLanguage(doc.data().language);
        setLoading(false)
      }
    });
  }

  const updateCollection = async () => {
    await updateDoc(doc(db, "customers", customerData.id), {language: language})
    let newCustomerData = customerData;
    newCustomerData.language = language;
    setCustomerData(newCustomerData);
    setChange(true);
  }

  
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
        getCollection(user.email);
      } else {
        setAuthUser(null);
        setLoading(false);
      }
    });

    return () => {
      listen();
    };
  }, []);
  
  return (
    <>
      {loading ? (
        <div className="loading loader"></div>
      ) : (
        <div>
          {authUser ? (
            <>
              <Header language={language}/>
              <div className="account-box">
                <h1>My Account</h1>
                <div className="record">
                  <label>email</label>
                    <input
                    type="email"
                    value={customerData.email}
                    readOnly
                    />
                </div>
                <div className="record">
                    <label>access</label>
                    <input
                    type="text"
                    value={customerData.subscribed || customerData.access ? "Subscribed" : "Not Subscribed"}
                    readOnly
                    />
                </div>
                <div className="language-box">
                  <LanguagePicker language={language} getLanguage={getLanguage}/>
                    <button className={language === customerData.language || change ? "button disable" : "button"} onClick={updateCollection}>{change ? 'saved' : 'save'}</button>
                </div>
                <a href="https://billing.stripe.com/p/login/eVaeXv54964C5nq288" className="button">Manage Subscription</a>
                <Link to={"/"} onClick={SignOut} className="button red">Log out</Link>{/* <img src={logout} alt="logout"/> */}
              </div>
            </>
          ):(
            <Navigate to='/signin'  />
          )}
        </div>
      )}
    </>
  );
};

export default Account;
