import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, collection, getDocs, updateDoc } from "firebase/firestore";

import "../styles/book.scss";

import BookComponent from "../components/BookComponent";

function Book() {
    const [authUser, setAuthUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [customerData, setCustomerData] = useState({
      id: '',
      email: '',
      language: '',
      access: '',
      subscribed: '',
      status: '',
      join_date: '',
    });

    const getCollection = async (email) => {
      const querySnapshot = await getDocs(collection(db, "customers"));
      querySnapshot.forEach((doc) => {
        if (email === doc.data().email) {
          setCustomerData({id: doc.id, ...doc.data()});
          setLoading(false);
        }
      });
    }

    const updateCollection = async (id, num) => {
      await updateDoc(doc(db, "customers", id), {trial: num});
    }
    
    useEffect(() => {
      const listen = onAuthStateChanged(auth, (user) => {
        if (user) {
          setAuthUser(user);
          getCollection(user.email);
        } else {
          setAuthUser(null);
          setLoading(false);
        }
      });

      return () => {
        listen();
      };
    }, []);

    return (
      <>
      {loading ? (
        <div className="loading loader"></div>
      ) : (
        <div>
          {authUser ? (
            <BookComponent customerData={customerData} setCustomerData={setCustomerData} updateCollection={updateCollection}/>
          ):(
            <Navigate to='/signin'  />
          )}
        </div>
      )}
      </>
    );
}
  
export default Book;