import { useState } from "react";

import france from "../icons/france.png"
import britain from "../icons/britain.png"

function LanguagePicker({language, getLanguage}) {
    const [lang, setLanguage] = useState(language);

    const chooseUk = () =>  {
        setLanguage("uk");
        getLanguage("uk");
    };
    const chooseFr = () =>  {
        setLanguage("fr")
        getLanguage("fr");
    };

    return (
        <div className="language-select">
            <img src={britain} onClick={chooseUk} alt="british" className={lang === "uk" ? "selected" : ""}/>
            <img src={france} onClick={chooseFr} alt="francais" className={lang === "fr" ? "selected" : ""}/>
        </div>
    );

}
  
export default LanguagePicker;