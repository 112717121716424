import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import "../styles/landing-page.scss";

import child_maths from "../images/child_maths.jpg"
import parent_bike from "../images/parent_bike.jpg"
import parents_walking from "../images/parents_walking.jpg"
// import homework from "../images/homework.jpg"
import parent_homework from "../images/parent_homework.jpg"
import screenshot from "../images/mobile_screenshot.png"
import screenshot_fr from "../images/mobile_screenshot_fr.png"
// import homework_pc from "../images/homework_pc.jpg"

import angry from "../icons/angry.png"
import chart from "../icons/chart.png"
import family from "../icons/family.png"
import time from "../icons/time.png"
import cheap from "../icons/cheap.png"
import LanguagePicker from "../components/LanguagePicker";


function LandingPage() {
    const paramLang = useParams().lang;
    const [language, setLanguage] = useState(paramLang === undefined ? "uk" : paramLang);

    const getLanguage = (lang) => {
      setLanguage(lang);
    }

    // reset window
    window.scrollTo(0, 0);

  return (
    <>
        <LanguagePicker language={language} getLanguage={getLanguage}/>
        {language === "uk" ? (
            <div className="landing-page">
                <div className="pair hero alternate-background">
                    <div className="between">
                        <h1>Transform Maths Homework into Fun Learning with our AI Maths Tutor!</h1>
                        <div className="paragraph">
                            Solve Maths problems in seconds!
                            Whether you need Theory or just want to solve Maths exercises, we got you!
                        </div>
                        <Link to={"/signin"} className="button width-100">Solve Maths Problems Now</Link>
                        <div className="text-center">or</div>
                        <Link to={"/pricing"} className="button second-button">See Pricing</Link>
                    </div>
                    <img className="between-img" src={parent_homework} alt="parent_homework"></img>
                </div>
                <div className="pair intro mobile-flip">
                    <img className="between-img" src={child_maths} alt="child_maths"></img>
                    <div className="between">
                        <div className="paragraph">
                            We aim to help parents understand their children's Maths homework and support
                            their learning journey. Our tool combines cutting-edge AI technology with
                            easy-to-understand explanations and examples to make Maths accessible and enjoyable
                            for everyone.
                        </div>
                        <div className="paragraph">
                            If you're a busy parent looking for a quicker and more efficient way to help
                            your child with homework, our AI Maths Tutor is the solution you've been searching for.
                            With two sections, Theory and Problem Solving, you can get a solid understanding of the
                            Maths concepts and tackle even the toughest problems with confidence.
                        </div>
                        <div className="paragraph">
                            Take a look at the Theory section to get a brief explanation of Maths concepts,
                            complete with examples. On the other side, the Problem Solving section provides step-by-step
                            guidance to help you solve Maths problems with ease.
                        </div>
                        <div className="paragraph">
                            Start exploring our AI Maths Tutor today and see the difference it can make for
                            you and your child's Maths education.
                        </div>

                    </div>
                </div>
                <div className="pair benefits alternate-background">
                    <div className="between">
                        <div className="benefit-list">
                            <div className="benefit-item">
                                <img src={chart} alt="chart"/>
                                <h2>Efficient Learning</h2>
                                <div>
                                    Clear and concise explanations and
                                    examples that make Maths concepts easy to understand.
                                </div>
                            </div>
                            <div className="benefit-item">
                                <img src={angry} alt="angry"/>
                                <h2>No frustration</h2>
                                <div>
                                    No more getting stuck on problems for hours on end. We all know how
                                    annoying it can be.
                                </div>
                            </div>
                            <div className="benefit-item">
                                <img src={time} alt="time"/>
                                <h2>Time Saving</h2>
                                <div>
                                    Save valuable time and effort with our step-by-step problem
                                    solving guidance. No more wasted hours trying to figure out how
                                    to solve a Maths problem or searching for the right resources.
                                </div>
                            </div>
                            <div className="benefit-item">
                                <img src={family} alt="family"/>
                                <h2>Time with Family</h2>
                                <div>
                                    Spend less time worrying about Maths homework and more time enjoying
                                    quality time with your family. Spend your evenings bonding and having
                                    fun, instead of struggling with Maths.
                                </div>
                            </div>
                            <div className="benefit-item">
                                <img src={cheap} alt="cheap"/>
                                <h2>Cost Effective</h2>
                                <div>
                                    Costs significantly less than a tutor.
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="between-img" src={parent_bike} alt="parent_bike"></img>
                </div>
                <div className="pair example">
                    <div className="between">
                        <h1>Example</h1>
                        <div className="paragraph">
                            Here is an example of the Theory Section answering a midrange level Maths question.
                            Showing off the efficient explanation with an easy to follow example.
                        </div>
                        <div className="paragraph">
                            To get more of a taste of what it can do, feel free to make an account and get some
                            free tries on the house! 
                        </div>
                    </div>
                    <img className="full-img" src={screenshot} alt="screenshot"/>
                </div>
                {/* <div className="pair testimonial mobile-flip alternate-background">
                    <img className="between-img" src={homework} alt="homework"></img>
                    <div className="between">
                        <h1>What Our Users Are Saying</h1>
                        <div className="paragraph">
                            "Our AI Maths Tutor has been a lifesaver for my family. My child can now solve
                            Maths problems with ease and I can help them without feeling overwhelmed. Thank
                            you for creating such a helpful tool!" - Sarah, Parent of 11yr old
                        </div>
                        <div className="paragraph">
                            "Lifesaver! we used to spend hours at a time in shouting matches" - Mark, Parent of 11yr old
                        </div>
                    </div>
                </div> */}
                <div className="outro">
                    <span>
                        We hope you find use out of our <b>Smart Maths Tutor</b>. If you have any suggestions please do
                        reach out, we have only just begun and all feedback is very valuable to us.
                    </span>
                    <Link to={"/signin"} className="button">Register/Sign in here</Link>
                </div>
                <div className="family">
                    <img className="end-img" src={parents_walking} alt="parents_walking"></img>
                </div>
                    {/* <img src={homework_pc}></img>
                    <img src={teaching}></img> */}
                <div className="contact">Any questions? Please reach out to <b>thomas@maths-tutor-ai.com</b></div>
            </div>
        ) : (
            <div className="landing-page">
                <div className="pair hero alternate-background">
                    <div className="between">
                        <h1>Transformez les devoirs de mathématiques en apprentissage amusant avec notre application IA Tuteur en maths!</h1>
                        <div className="paragraph">
                            Résolvez des problèmes de maths en quelques secondes!
                            Que vous ayez besoin de théorie ou que vous souhaitiez simplement résoudre des exercices de mathématiques, nous avons ce qu'il vous faut!
                        </div>
                        <Link to={"/signin"} className="button width-100">Résoudre vos problèmes de maths maintenant</Link>
                        <div className="text-center">ou</div>
                        <Link to={"/pricing"} className="button second-button">Voir les prix</Link>
                    </div>
                    <img className="between-img" src={parent_homework} alt="parent_homework"></img>
                </div>
                <div className="pair intro mobile-flip">
                    <img className="between-img" src={child_maths} alt="child_maths"></img>
                    <div className="between">
                        <div className="paragraph">
                            Notre objectif est d'aider les parents à comprendre les devoirs et de soutenir le parcours d'apprentissage en
                            mathématique de leurs enfants. Notre outil combine une technologie d'IA de pointe avec
                            des explications et des exemples faciles à comprendre pour rendre les mathématiques accessibles et agréables
                            pour tout le monde.
                        </div>
                        <div className="paragraph">
                            Si vous êtes un parent occupé à la recherche d'un moyen plus rapide et plus efficace d'aider
                            votre enfant à faire ses devoirs, notre tuteur en mathématiques IA est la solution que vous recherchiez.
                            Avec deux sections, "Théorie" et "Résolution de Problèmes", vous pourrez acquérir une solide compréhension
                            des concepts de maths et aborderez même les problèmes les plus difficiles avec confiance.
                        </div>
                        <div className="paragraph">
                            Jetez un œil à la section "Théorie" pour obtenir une brève explication des concepts de maths,
                            illustrée par des exemples. D'autre part, la section "Résolution" de problèmes fournit étape par étape
                            des conseils pour vous aider à résoudre facilement les problèmes de maths.
                        </div>
                        <div className="paragraph">
                            Commencez à explorer notre tuteur en mathématiques IA dès aujourd'hui et voyez la différence que cela peut faire pour
                            vous et l'éducation en mathématiques de votre enfant.
                        </div>

                    </div>
                </div>
                <div className="pair benefits alternate-background">
                    <div className="between">
                        <div className="benefit-list">
                            <div className="benefit-item">
                                <img src={chart} alt="chart"/>
                                <h2>Apprentissage Efficace</h2>
                                <div>
                                    Des explications claires et concises et
                                    des exemples qui facilitent la compréhension des concepts de maths.
                                </div>
                            </div>
                            <div className="benefit-item">
                                <img src={angry} alt="angry"/>
                                <h2>Pas de frustration</h2>
                                <div>
                                    Plus besoin de rester bloqué sur des problèmes pendant des heures. Nous savons tous comme cela
                                    peut être frustrant.
                                </div>
                            </div>
                            <div className="benefit-item">
                                <img src={time} alt="time"/>
                                <h2>Gain de Temps</h2>
                                <div>
                                    Économisez un temps et des efforts précieux avec nos conseils de résolution, étape par étape.
                                    Plus d'heures perdues à essayer de comprendre comment résoudre un problème de maths ou chercher
                                    les bonnes ressources.
                                </div>
                            </div>
                            <div className="benefit-item">
                                <img src={family} alt="family"/>
                                <h2>Temps avec la famille</h2>
                                <div>
                                    Passez moins de temps à vous soucier des devoirs de maths et plus de temps à vous amuser
                                    et vous détendre.
                                </div>
                            </div>
                            <div className="benefit-item">
                                <img src={cheap} alt="cheap"/>
                                <h2>Économique</h2>
                                <div>
                                    Coûte nettement moins cher qu'un tuteur.
                                </div>
                            </div>
                        </div>
                    </div>
                    <img className="between-img" src={parent_bike} alt="parent_bike"></img>
                </div>
                <div className="pair example">
                    <div className="between">
                        <h1>Exemple</h1>
                        <div className="paragraph">
                            Voici un exemple de la section "Théorie" répondant à une question de maths de niveau intermédiaire.
                            La réponse comporte une explication compréhensive, illustrée par un exemple facile à suivre.
                        </div>
                        <div className="paragraph">
                            Pour avoir un avant-goût de ce que cette application pour vous apporter, n'hésitez pas à créer un
                            compte et à faire des essais gratuits.
                        </div>
                    </div>
                    <img className="full-img" src={screenshot_fr} alt="screenshot"/>
                </div>
                {/* <div className="pair testimonial mobile-flip alternate-background">
                    <img className="between-img" src={homework} alt="homework"></img>
                    <div className="between">
                        <h1>Ce que disent nos utilisateurs</h1>
                        <div className="paragraph">
                            en attente des retours clients
                        </div>
                    </div>
                </div> */}
                <div className="outro">
                    <span>
                        Nous espérons que vous trouverez l'utilisation de notre <b>tuteur de mathématiques intelligent</b>.
                        Si vous avez des suggestions, n'hésitez pas à nous contacter, nous venons tout juste de commencer et tous
                        les commentaires sont très précieux pour nous.
                    </span>
                    <Link to={"/signin"} className="button">Connexion/Créer un compte</Link>
                </div>
                <div className="family">
                    <img className="end-img" src={parents_walking} alt="parents_walking"></img>
                </div>
                    {/* <img src={homework_pc}></img>
                    <img src={teaching}></img> */}
                <div className="contact">Des questions? Veuillez contacter <b>thomas@maths-tutor-ai.com</b></div>
            </div>
            )}
    </>
  );
}

export default LandingPage;
