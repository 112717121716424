import {Configuration, OpenAIApi} from "openai";

async function GPT(prompt) {
    const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPEN_AI_Key,
    });
    const openai = new OpenAIApi(configuration);

    return await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: [{role: "user", content: prompt}], 
        temperature: 0,
        max_tokens: 1500,
        top_p: 1.0,
        frequency_penalty: 0.0,
        presence_penalty: 0.0,
      });

}

export default GPT;