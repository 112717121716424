import React, { useState } from "react";
import { auth, db } from "../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { collection, addDoc } from "firebase/firestore";
import LanguagePicker from "../LanguagePicker";

const SignUp = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [language, setLanguage] = useState("uk");

  const createCollection = async (email) => {
    try {
      let newDate = new Date()
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      newDate = `${year}-${month<10?`0${month}`:`${month}`}-${date}`

      await addDoc(collection(db, "customers"), {
        email,
        language,
        access: false,
        subscribed: false,
        status: "early bird",
        join_date: newDate,
        trial: 11,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }

  const signUp = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        createCollection(email);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getLanguage = (lang) => {
    setLanguage(lang);
  }

  return (
    <div  className="box">
      <form onSubmit={signUp}>
        <h1>Create Account</h1>
        <div className="inputBox">
            <label>email</label>
            <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
        </div>
        <div className="inputBox">
            <label>password</label>
            <input
            type="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
        </div>
        <div className="inputBox">
            <label>confirm password</label>
            <input
            type="password"
            placeholder="Confirm your password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            />
        </div>
        <div className="inputBox">
          <div className="language-label">preferred language:</div>
          <LanguagePicker language={language} getLanguage={getLanguage}/>
        </div>
        <button type="submit" name="sign-up" className="button">Sign Up</button>
      </form>
    </div>
  );
};

export default SignUp;