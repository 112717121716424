import React, { useState } from "react";
import { Link } from "react-router-dom";

import LanguagePicker from "./LanguagePicker";
import homework from "../images/homework.jpg"

import tick from "../icons/tick.png"
import "../styles/payment.scss";

const NotSubscribed = ({lang}) => {
  const [language, setLanguage] = useState(lang);

  const getLanguage = (lang) => {
    setLanguage(lang);
  }
  return (
    <>
      <div className="avoid-header"/>
      <LanguagePicker language={language} getLanguage={getLanguage}/>
      <div className="pricing-backgroud">
        {language === "uk" ? (
          <div className="pricing">
            <h1 className="pricing-heading">You've run out of free spins</h1>
            <h2 className="pricing-heading-2">
              <div>No need to get another tutor.</div>
              <div>You can become your child's maths tutor with <b>no effort!</b></div>
            </h2>
              <div className="tick-list">
                <span><img src={tick} alt="tick"/> cancel anytime</span>
                <span><img src={tick} alt="tick"/> easy sign up</span>
                <span><img src={tick} alt="tick"/> no unecessary personal data required</span>
              </div>
            <Link to="/pricing/uk" className="button">See Pricing Options</Link>
          </div>
        ) : (
          <div className="pricing">
            <h1 className="pricing-heading">Vous n'avez plus d'essais gratuit</h1>
            <h2 className="pricing-heading-2">
              <div>Pas besoin d'engager un autre tuteur.</div>
              <div>Devenez le tuteur de maths de votre enfant <b>sans efforts!</b></div>
            </h2>
              <div className="tick-list">
                <span><img src={tick} alt="tick"/> sans aucun engagement, annulez à tout moment</span>
                <span><img src={tick} alt="tick"/> inscription facile</span>
                <span><img src={tick} alt="tick"/> aucune donnée personnelle inutile demandée</span>
              </div>
            <Link to="/pricing/fr" className="button">Voir les options de tarification</Link>
          </div>
        )}
      </div>
      <div>
        <div className="pricing-more-info">
          <div className="pricing-split">
            <img className="between-img" src={homework} alt="homework"></img>
          </div>
          {language === "uk" ? (
            <div className="pricing-split">
              <h1 className="more-info-heading">
                With the maths tutor AI, you'll never have to struggle with maths again
              </h1>
              <h2 className="more-info-heading-2">
                <div>Is maths homework taking a long time?</div>
                <div>Are you trying to remember maths that you learnt years ago?</div>
                <div><b>No more!</b></div>
              </h2>
              <Link to="/pricing/uk" className="button">Join now to become stress free!</Link>
            </div>
          ) : (
            <div className="pricing-split">
              <h1 className="more-info-heading">
                Avec l'application IA Tuteur en maths, vous n'aurez plus jamais de problèmes en maths
              </h1>
              <h2 className="more-info-heading-2">
                <div>Les devoirs de maths prennent-ils trop de temps?</div>
                <div>Essayez-vous de vous souvenir des maths de votre jeunesse?</div>
                <div><b>C'est terminé!</b></div>
              </h2>
              <Link to="/pricing/fr" className="button">Abonnez-vous maintenant pour éliminer le stress!</Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default NotSubscribed;