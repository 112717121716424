const removeFirstBreakLines = (answer) => {
  if (answer.indexOf("!!") !== -1 && answer.indexOf("!!") < 2) {
    if (answer.indexOf("!!", answer.indexOf("!!") + 2) !== -1 && answer.indexOf("!!", answer.indexOf("!!") + 2) < 8) {
      return answer.substring(answer.indexOf("!!") + 7);
    }
    return answer.substring(answer.indexOf("!!") + 2);
  }
  return answer;
}

// break up text (words and formula)
function TextFormat(answer) {
    // remplace double \n with !! to prevent confusion
    answer = answer.replace(/\n\n/g, " !! ");
    // remplace the single \n with !! to prevent confusion
    answer = answer.replace(/\n/g, " !! ");
    // remove all extra spaces
    answer = answer.replace(/"/g, '" ');
    // remove all :
    answer = answer.replaceAll(":", "");

    // if answer starts with breaklines
    answer = removeFirstBreakLines(answer);
    answer = answer.substring(1);

    let answerArray = answer.split(" !! ");
    return answerArray;
}
  
export default TextFormat;