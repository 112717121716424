import "./App.scss";
import "./styles/website.scss";
import "./styles/padding.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import SignInPage from "./pages/SignInPage";
import ForgotPassword from "./pages/ForgotPassword";
import Tutor from "./pages/Tutor";
import Book from "./pages/Book";
import Account from "./pages/Account";
import Subscribed from "./pages/Subscribed";
import LandingPage from "./pages/LandingPage";
import Pricing from "./pages/Pricing";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" exact={true} element={<Home />} />
          <Route path="/signin" exact={true} element={<SignInPage />} />
          <Route path="/forgot-password" exact={true} element={<ForgotPassword />} />
          <Route path="/tutor/:lang/:modeSelected" exact={true} element={<Tutor />} />
          <Route path="/book/:lang" exact={true} element={<Book />} />
          <Route path="/account" exact={true} element={<Account />} />
          <Route path="/subscribed" exact={true} element={<Subscribed />} />
          <Route path="/pricing/:lang?" exact={true} element={<Pricing />} />
          <Route path="/landing-page/:lang?" exact={true} element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

// homeworkhero