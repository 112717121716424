import { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import "../styles/forgotten-password.scss";
import { Link } from "react-router-dom";

function ForgotPassword() {
    const [email, setEmail] = useState("");

    const resetPassword = () => {
        sendPasswordResetEmail(email)
        .then((userCredential) => {
          console.log(userCredential);
        })
        .catch((error) => {
          console.log(error);
        });
    }

  return (
    <div className="forgotten-passowrd">
      <h1>Currently not Available</h1>
      <p>Please contact <b>thomas@maths-tutor-ai.com</b> for support</p>
      {/* <form onSubmit={resetPassword}>
        <h1>Reset your password</h1>
        <div className="inputBox">
            <label>email</label>
            <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
        </div>
        <button type="submit" name="sign-in">Reset Password</button>
      </form> */}
      <Link to="/" className="forgotten-passowrd-change">Back to log in</Link>
    </div>
  );
}

export default ForgotPassword;
