

export const getPrompt = (language, mode, input) => {
    if (language === "uk") {
      if (mode === "theory") {
        return `Act like a maths tutor for this situation: Explain the theory of ${input} with examples. Provide the output in text with only the maths being provided in react-katex. For example: <InlineMath math="\frac{23}{45} + 2" />.`;
      } else if (mode === "solve") {
        return `Act like a maths tutor, solving a problem fully, for this situation: ${input} with a breakdown of all the steps. Start by introducing what the problem is and then, provide the output in text with only the maths being provided in react-katex. For example: <InlineMath math="\frac{23}{45} + 2" />.`;
      } else if (mode === "question") {
        return `${input}`;
      }
    } else {
      if (mode === "theory") {
        return `Agissez comme un tuteur de mathématiques pour cette situation: Expliquez la théorie de ${input} avec des exemples. Fournissez la réponse sous forme de texte, seuls les maths étant écrit dans react-katex. Par exemple: <InlineMath math="\frac{23}{45} + 2" />.`;
      } else if (mode === "solve") {
        return `Agissez comme un tuteur de mathématiques, en résolvant le problème suivant, pour cette situation: ${input} décomposé en plusieurs étapes. Commencez par présenter le problème, puis, Fournissez la réponse sous forme de texte, seuls les maths étant écrit dans react-katex. Par exemple: <InlineMath math="\frac{23}{45} + 2" />.`;
      } else if (mode === "question") {
        return `${input}`;
      }
    }
  }

  export const buttonNames = {
    uk: {
      question: "Ask",
      theory: "Explain",
      solve: "Solve",
    },
    fr: {
      question: "Go",
      theory: "Explique",
      solve: "Résoudre",
    }
  }

  export const placeholder = {
    uk: {
      question: "Ask away :)",
      theory: "Enter only the topic here. For example 'multiply fractions'.",
      solve: "Input problem here",
    },
    fr: {
      question: "Question ici :)",
      theory: "Entrez uniquement le sujet ici. Par exemple, 'multiplier des fractions'.",
      solve: "Entrez le problème ici.",
    }
  };

  export const rawTextButton = ["Show raw text", "Show formatted text"];