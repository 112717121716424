import { InlineMath } from 'react-katex';

import 'katex/dist/katex.min.css';

const checkMathsString = (partOfSentence) => {
  return partOfSentence.substr(0, 6) === `math="`;
}

let prevIsMaths = false;

// restore Latex formatting so that it works
const checkForwardSlashString = (word) => {
  let firstPart = word.substr(0, 1);
  if (firstPart === '') { // if gpt returns that weird char
    if (word.substr(1, 3) === "rac") return "\\f" + word.substring(1);
    return "\\" + word.substring(1);
  } else if (firstPart === `\\`) { // if gpt wants to explain that it is showing react-katex
    if (word.substr(0, 2) === `\\\\`) return word.substring(1);
    return word;
  } else if (firstPart === `\b`) {
    return "\\b" + word.substring(1);
  } else if (firstPart === `\f`) {
    return "\\f" + word.substring(1);
  } else if (firstPart === `\n`) {
    return "\\n" + word.substring(1);
  } else if (firstPart === `\r`) {
    return "\\r" + word.substring(1);
  } else if (firstPart === `\t`) {
    return "\\t" + word.substring(1);
  } else if (firstPart === `\v`) {
    return "\\v" + word.substring(1);
  } else {
    return word;
  }
}

const removeFirstBreakLines = (answer) => {
  if (answer.indexOf("!!") !== -1 && answer.indexOf("!!") < 2) {
    if (answer.indexOf("!!", answer.indexOf("!!") + 2) !== -1 && answer.indexOf("!!", answer.indexOf("!!") + 2) < 8) {
      return answer.substring(answer.indexOf("!!") + 7);
    }
    return answer.substring(answer.indexOf("!!") + 2);
  }
  return answer;
}

// break up text (words and formula)
function TextFormat(answer) {
  // remplace \n with !! to prevent confusion
  answer = answer.replace(/\n/g, " !! ");
  // remove all extra spaces
  answer = answer.replace(/"/g, '" ');
  // remove all double equations
  answer = answer.replace(/> </g, '> and <');
  // answer = answer.replace(/> !! </g, '> and <');

  // if answer starts with breaklines
  answer = removeFirstBreakLines(answer);

  let tempArray = answer.split(" ");
  
  let j = 0;
  let answerArray = [];
  tempArray.map((word) => {
    if (j % 2 !== 0) {
      word = checkForwardSlashString(word);
    }
    if (word === "<InlineMath" || word === "/>" || word === "/>," || word === "/>." || word === "/>;" || word === "/>:") {
      j++;
    } else {
      if (answerArray[j] === undefined) {answerArray[j] = word + " ";}
      else {answerArray[j] = answerArray[j] + word + " ";}
    }
    return null;
  });

  j = 0;
  answerArray.map((partOfSentence) => {
    if (j % 2 !== 0) {
      if (checkMathsString(partOfSentence)) {
        partOfSentence = partOfSentence.split(`"`);
        answerArray[j] = partOfSentence[1].substring(1) + " ";
      }
    }
    j++;
    return null;
  });
  return answerArray;
}

const getPrevIndex = (index, breakCounter) => {
  if (index === 0) return 0;
  else {
    if (breakCounter[index] - breakCounter[index-1] > 5) {
      return breakCounter[index-1] + 3;
    } else {
      return breakCounter[index] - 1;
    }
  };
}

export const addBreakLines = (partOfSentence, i) => {
  let start = false;
  let end = false;
  if (i % 2 === 0) {
    // remove excess spaces
    partOfSentence = partOfSentence.replace(/\s+/g, ' ').trim();
    let breakCounter = [];
    let index = 0;

    while (partOfSentence.indexOf("!!", index) !== -1) {
      if (partOfSentence.indexOf("!!", index) < 4 && start !== true) {
        start = true;
      } else if (partOfSentence.indexOf("!!", index) > 4 && end !== true) {
        end = true;
      }
      breakCounter.push(partOfSentence.indexOf("!!", index));
      index = partOfSentence.indexOf("!!", index) + 2;
    }

    let newPartOfSentence = [];
    // remove the !! from the sentence
    for (let jndex = 0; jndex < breakCounter.length; jndex++) {
      let temp = partOfSentence.substring(getPrevIndex(jndex, breakCounter), breakCounter[jndex] - 1)
      if (temp !== "") {
        newPartOfSentence.push(temp);
      }
      // get sentence if there is no breakline at the end
      if (jndex === breakCounter.length -1 && breakCounter[breakCounter.length - 1] + 3 < partOfSentence.length) {
        newPartOfSentence.push(partOfSentence.substring(breakCounter[jndex] + 3));
      }
    }

    // print the different posibilities of breaklines
    if (breakCounter.length === 0) {
      //remove tracker for double maths
      if (prevIsMaths && partOfSentence.length !== 0) {
        prevIsMaths = false;
      }
      return (
        <span>
          <span>{partOfSentence}</span>
        </span>
      );
    } else {
      //remove tracker for double maths
      if (prevIsMaths && newPartOfSentence.length !== 0) {
        prevIsMaths = false;
      }
      if (start && end) {
        return (
          <span>
            {newPartOfSentence.map((text, index) => (
              <span>
                <br/>
                <br/>
                <span key={index}>{text}</span>
                <br/>
                <br/>
              </span>
            ))}
          </span>
        );
      } else if (start) {
        return (
          <span>
            {newPartOfSentence.map((text, index) => (
              <span>
                <br/>
                <br/>
                <span key={index}>{text}</span>
              </span>
            ))}
          </span>
        );
      } else if (end) {
        return (
          <span>
            {newPartOfSentence.map((text, index) => (
              <span>
                <span key={index}>{text}</span>
                <br/>
                <br/>
              </span>
            ))}
          </span>
        );
      } else {
        return (
          <span>
            {newPartOfSentence.map((text, index) => (
              <span>
                <span key={index}>{text}</span>
              </span>
            ))}
          </span>
        );
      }
    }
  } else {
    // display math in LAtex (with br if there is math in the previous partOfSentence)
    if (prevIsMaths){
      return (
        <em className="maths">
          <br/>
          <br/>
          <InlineMath math={partOfSentence} key={i}/>
        </em>
      );
    } else {
      prevIsMaths = true;
      return (
        <em className="maths">
          <InlineMath math={partOfSentence} key={i}/>
        </em>
      );
    }
  }
}

export const addBreakLinesTest = (partOfSentence, i) => {
  const isEven = i % 2 === 0;
  const breakIndexes = [];

  if (isEven) {
    // Remove excess spaces
    partOfSentence = partOfSentence.replace(/\s+/g, ' ').trim();

    // Find indexes of all breaklines in the sentence
    let index = 0;
    while ((index = partOfSentence.indexOf("!!", index)) !== -1) {
      breakIndexes.push(index);
      index += 2;
    }

    // Split sentence into parts separated by breaklines
    const parts = [];
    let start = 0;
    for (const breakIndex of breakIndexes) {
      const part = partOfSentence.substring(start, breakIndex).trim();
      if (part) {
        parts.push(part);
      }
      start = breakIndex + 2;
    }
    const lastPart = partOfSentence.substring(start).trim();
    if (lastPart) {
      parts.push(lastPart);
    }

    // Render sentence with or without breaklines
    const hasBreaklines = breakIndexes.length > 0;
    const hasStartBreakline = hasBreaklines && breakIndexes[0] < 4;
    const hasEndBreakline = hasBreaklines && breakIndexes[breakIndexes.length - 1] + 3 < partOfSentence.length;
    const breakline = <><br/><br/></>;
    const renderedParts = parts.map((part, index) => (
      <span key={index}>
        {index > 0 && breakline}
        {part}
      </span>
    ));

    return (
      <span>
        {renderedParts}
        {hasEndBreakline && breakline}
      </span>
    );
  } else {
    // Render math formula
    const renderedMath = (
      <em className="maths">
        <InlineMath math={partOfSentence}/>
      </em>
    );

    // Add breaklines before or after math formula if necessary
    const hasBreaklines = prevIsMaths;
    prevIsMaths = true;

    return (
      <span>
        {hasBreaklines && <><br/><br/></>}
        {renderedMath}
      </span>
    );
  }
}

  
export default TextFormat;