import React, {useState} from "react";

import "../styles/popup.scss";


function PopUp({mode, trial, language}) {
    const [popUp, setPopUp] = useState(trial);

    const helpButton = language === "uk" ? "Help" : "Aide";
    
    const toggle = () => {
        setPopUp(!popUp);
    };
    

  if(popUp) {
    document.body.classList.add('active-popup')
  } else {
    document.body.classList.remove('active-popup')
  }
  
    return (
        <>
            <button onClick={toggle} className="button">{helpButton}</button>

            {popUp && (
            <div className="popup">
                <div onClick={toggle} className="overlay"></div>
                <div className="popup-content">
                    <>
                        {language === "uk" ? (
                            <>
                                <h2 className="highlight">
                                    {mode === "question" ? " Generic Question" : (
                                        mode === "theory" ? " Theory" : " Maths Exercises"
                                    ) } section
                                </h2>
                                <p>
                                    It is important that you understand how best to use this 
                                    section as entering the wrong text can lead to <b className="highlight">weird answers</b> 
                                </p>
                                {mode === "question"
                                    ? <p>In this case, you are free to ask <b className="highlight">any form of question</b> you want.</p> : (
                                mode === "theory"
                                    ? <p>In this case, make sure you <b className="highlight">only enter the subject</b> you would like information on.</p> : (
                                mode === "solve"
                                        ? <p>In this case, make sure you <b className="highlight">only enter the problem</b> you wish to solve.</p>
                                        : ""
                                    )
                                ) }
                                <span>
                                    For example: 
                                </span>
                                <div className="example-text">
                                    {mode === "question"
                                        ? <>
                                            <span className="padding-bottom-15">If you want to ask the question, what is a protractor, all you need to do is write the question:</span>
                                            <span>"what is a protractor?"</span>
                                        </> : (
                                    mode === "theory"
                                        ? <>
                                            <span className="padding-bottom-15">If you want to know how to divide fractions, all you need to do is type in the subject:</span>
                                            <span>"divide fractions"</span>
                                        </> : (
                                    mode === "solve"
                                        ? <>
                                            <span className="padding-bottom-15">If you want to know how to solve for x, 3x = 23, all you need to do is type in the problem:</span>
                                            <span>"3(x + 12) = 23"</span>
                                        </> : "" )
                                    ) }
                                </div>
                                <button className="button" onClick={toggle}>
                                    Acknowledge
                                </button>
                            </>
                        ) : (
                            <>
                                <h2 className="highlight">
                                    Section {mode === "question" ? " Question Générique" : (
                                        mode === "theory" ? " Théorie" : " Excercises de Maths"
                                    ) }
                                </h2>
                                <p>
                                Il est important que vous compreniez comment utiliser au mieux cette section, car entrer un texte incorrect peut conduire à des <b className="highlight">réponses étranges.</b> 
                                </p>
                                {mode === "question"
                                    ? <p>Dans ce cas, vous êtes libre de poser <b className="highlight">n'importe quelle forme de question</b> que vous voulez.</p> : (
                                mode === "theory"
                                    ? <p>Dans ce cas, assurez-vous d'entrer <b className="highlight">uniquement le sujet</b> sur lequel vous souhaitez obtenir des informations.</p> : (
                                mode === "solve"
                                        ? <p>Dans ce cas, assurez-vous d'entrer <b className="highlight">uniquement le problème</b> que vous souhaitez résoudre.</p>
                                        : ""
                                    )
                                ) }
                                <span>
                                    Par exemple: 
                                </span>
                                <div className="example-text">
                                    {mode === "question"
                                        ? <>
                                            <span className="padding-bottom-15">Si vous voulez poser la question, qu'est-ce qu'un rapporteur, tout ce que vous avez à faire est d'écrire la question:</span>
                                            <span>"Qu'est-ce qu'un rapporteur ?"</span>
                                        </> : (
                                    mode === "theory"
                                        ? <>
                                            <span className="padding-bottom-15">Si vous voulez savoir comment diviser des fractions, tout ce que vous avez à faire est de taper le sujet:</span>
                                            <span>"diviser des fractions"</span>
                                        </> : (
                                    mode === "solve"
                                        ? <>
                                            <span className="padding-bottom-15">Si vous voulez savoir comment résoudre pour x dans 3x = 23, tout ce que vous avez à faire est de entrer le problème :</span>
                                            <span>"3(x + 12) = 23"</span>
                                        </> : "" )
                                    ) }
                                </div>
                                <button className="button" onClick={toggle}>
                                    Compris
                                </button>
                            </>
                        )}
                    </>
                </div>
            </div>

            )}
        </>
    );
}
  
export default PopUp;