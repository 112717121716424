import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";

import Header from "../components/Header";
import LanguagePicker from "../components/LanguagePicker";

import tick from "../icons/tick.png"

import "../styles/payment.scss";

const Pricing = () => {
  const paramLang = useParams().lang;
  const [language, setLanguage] = useState(paramLang === undefined ? "uk" : paramLang);
  const [authUser, setAuthUser] = useState(null);

  const getLanguage = (lang) => {
    setLanguage(lang);
  }

  
  useEffect(() => {
    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => {
      listen();
    };
  }, []);

  return (
    <>
      <Header language={language}/>
      <div className="avoid-header"/>
      <LanguagePicker language={language} getLanguage={getLanguage}/>
      <div className="pricing-backgroud">
        <div className="pricing">
          {language === "uk" ? (
            <div className="pricing-split">
              <h1 className="pricing-heading">Bye bye maths textbooks!</h1>
              <h2 className="pricing-heading-2">
                <div>No need to get another tutor.</div>
                <div>You can become your child's maths tutor with <b>no effort!</b></div>
                <div>
                  Say goodbye to boring and confusing maths textbooks! With Maths Tutor AI, you'll never 
                  have to struggle with maths again.
                </div>
              </h2>
              <div className="tick-list">
                <span><img src={tick} alt="tick"/> cancel anytime</span>
                <span><img src={tick} alt="tick"/> easy sign up</span>
                <span><img src={tick} alt="tick"/> no unecessary personal data required</span>
              </div>
            </div>
          ) : (
            <div className="pricing-split">
              <h1 className="pricing-heading">Adieu les manuels de maths!</h1>
              <h2 className="pricing-heading-2">
                <div>Plus besoin de prendre un nouveau tuteur.</div>
                <div>Vous pouvez devenir le tuteur en maths de votre enfant <b>sans efforts!</b></div>
                <div>
                  Dites adieu aux manuels de maths ennuyeux et déroutants! Avec l'application, vous n'aurez plus jamais de problèmes en maths.
                </div>
              </h2>
              <div className="tick-list">
                <span><img src={tick} alt="tick"/> sans aucun engagement, annulez à tout moment</span>
                <span><img src={tick} alt="tick"/> inscription facile</span>
                <span><img src={tick} alt="tick"/> aucune donnée personnelle inutile demandée</span>
              </div>
            </div>
          )}
        </div>
      </div>
      
      <div className="price-selection">
        {language === "uk" ? (
          <div className="payment-box free">
            <div className="payment-price">Free Account</div>
            <Link to='/signin' className="button">Sign in/Create an account</Link>
            <div className="line"></div>
            <div className="muted-text padding-bottom-15"><b>Free</b></div>
            <div className="muted-text">No payments</div>
            <div className="line"></div>
            <ul className="payment-benefits">
              <li>able to test out the app</li>
              <li>5 free tries</li>
            </ul>
          </div>
        ) : (
          <div className="payment-box free">
            <div className="payment-price">Compte Gratuit</div>
            <Link to='/signin' className="button">Connexion/Créer un compte</Link>
            <div className="line"></div>
            <div className="muted-text padding-bottom-15"><b>Gratuit</b></div>
            <div className="muted-text">Pas de paiement</div>
            <div className="line"></div>
            <ul className="payment-benefits">
              <li>vous pouvez tester l'application</li>
              <li>5 essais gratuits</li>
            </ul>
          </div>
        )}

        {language === "uk" ? (
          <div className="payment-box">
            <div className="payment-price">Early Bird Access</div>
            <Link to={authUser ? "https://buy.stripe.com/7sIcP969Nc4I5k4bII" : "/signin"} className="button">Subscribe for only £3.95</Link>
            <div className="line"></div>
            <div className="muted-text padding-bottom-15"><b>£3.95</b>/month</div>
            <div className="muted-text">Billed monthly</div>
            <div className="line"></div>
            <ul className="payment-benefits">
              <li>same benefits as standard</li>
              <li>much cheaper than standard access</li>
              <li>option to help shape the future of this tool</li>
              <li>only available for the <b>first 100 users</b></li>
            </ul>
          </div>
        ) : (
          <div className="payment-box">
            <div className="payment-price">Accès Early Bird</div>
            <Link to={authUser ? "https://buy.stripe.com/7sIcP969Nc4I5k4bII" : "/signin"} className="button">Abonnez-vous</Link>
            <div className="line"></div>
            <div className="muted-text padding-bottom-15"><b>£3.95</b>/mois</div>
            <div className="muted-text">Factures mensuelles</div>
            <div className="line"></div>
            <ul className="payment-benefits">
              <li>bénéficiez des mêmes avantages que l'accès Standard</li>
              <li>beaucoup moins cher qu'un accès standard</li>
              <li>option pour aider à façonner l'avenir de cet outil</li>
              <li>uniquement disponible pour les <b>100 premiers utilisateurs</b></li>
            </ul>
          </div>
        )}

        {language === "uk" ? (
          <div className="payment-box">
            <div className="payment-price">Standard Access</div>
            <Link to={authUser ? "https://buy.stripe.com/14k5mH1TxecQbIsfYZ" : "/signin"} className="button">Subscribe</Link>
            <div className="line"></div>
            <div className="muted-text padding-bottom-15"><b>£20</b>/month</div>
            <div className="muted-text">Billed monthly</div>
            <div className="line"></div>
            <ul className="payment-benefits">
              <li>quick access to maths resources</li>
              <li>easy to follow breakdown of maths theory with examples</li>
              <li>step-by-step breakdowns of your maths problems</li>
              <li>works in every language*!!</li>
            </ul>
          </div>
        ) : (
          <div className="payment-box">
            <div className="payment-price">Accès Standard</div>
            <Link to={authUser ? "https://buy.stripe.com/14k5mH1TxecQbIsfYZ" : "/signin"} className="button">Abonnez-vous</Link>
            <div className="line"></div>
            <div className="muted-text padding-bottom-15"><b>£20</b>/mois</div>
            <div className="muted-text">Factures mensuelles</div>
            <div className="line"></div>
            <ul className="payment-benefits">
              <li>accès rapide aux ressources de maths</li>
              <li>explications faciles à suivre de la théorie des maths, à l'aide d'exemples</li>
              <li>décompositions, étapes par étapes de vos problèmes de maths</li>
              <li>fonctionne dans toutes les langues*!!</li>
            </ul>
          </div>
        )}
      </div>

      {language === "uk" ? (
        <div className="pricing-backgroud about-us">
          <h2>Still not sure?</h2>
          <div>Check out more about us and what we can offer.</div>
          <Link to="/landing-page/uk" className="button">Find out more info about us here</Link>
        </div>
      ) : (
        <div className="pricing-backgroud about-us">
          <h2>Toujours indécis?</h2>
          <div>En savoir plus sur nous et ce que nous pouvons offrir.</div>
          <Link to="/landing-page/fr" className="button">En savoir plus sur nous ici</Link>
        </div>
      )}
    </>
  );
};

export default Pricing;
