import React, { useEffect } from "react";
import { auth, db } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, collection, getDocs, updateDoc } from "firebase/firestore";

import "../styles/subscribed.scss";

import champagne from "../icons/champagne.png"

function Subscribed() {
  
  useEffect(() => {
    const updateCollection = async (id) => {
      await updateDoc(doc(db, "customers", id), {subscribed: true});
    }
  
    const getCollection = async (email) => {
      const querySnapshot = await getDocs(collection(db, "customers"));
      querySnapshot.forEach((doc) => {
        if (email === doc.data().email) {
          updateCollection(doc.id);
        }
      });
    }

    const listen = onAuthStateChanged(auth, (user) => {
      if (user) {
        getCollection(user.email);
      }
    });

    return () => {
      listen();
    };
  }, []);

  return (
    <div className="subscribed">
      <img src={champagne} alt="champagne"/>
      <h1>Subscription Payment Confirmation</h1>
      <p>Thank you for your subscription payment! Your payment has been processed successfully.</p>
      <a href="/" className="button">Get Started</a>
    </div>
  );
}

export default Subscribed;
