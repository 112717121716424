import React, { useState } from "react";
import { Link } from "react-router-dom";

import LanguagePicker from "./LanguagePicker";

import "../styles/tutor-selector.scss";


const TutorSelector = ({lang}) => {
  const [language, setLanguage] = useState(lang);

  const getLanguage = (lang) => {
    setLanguage(lang);
  }

  return (
    <div className="include-language">
      <LanguagePicker language={language} getLanguage={getLanguage}/>
      <div className="gpt-options">
        <> {language === "uk" ? (
          <>
            <Link to="/tutor/uk/question" className="card">
              <h1>Generic Question</h1>
              <span>Ask questions here.</span>
            </Link>
            <Link to="/tutor/uk/theory" className="card">
              <h1>Theory</h1>
              <span>Get a breakdown of how something works with examples to help you understand how to solve questions</span>
            </Link>
            <Link to="/tutor/uk/solve" className="card">
              <h1>Maths Excercises</h1>
              <span>Stuck on a question? Get a breakdown on how to solve it here</span>
            </Link>
            <Link to="/book/uk" className="card">
              <h1>Book Summary</h1>
              <span>Need to check if your child has read a book? or need a quick summary for last minute homework? This is the section for you!</span>
            </Link>
            <div className="card coming-soon">
              <h1>Let us know <br/> What you want next</h1>
              <span>We are growing our projects and we want parents to help us decide what to do next. Send us an email to <b>thomas@maths-tutor-ai.com</b></span>
            </div>
          </>
        ) : (
          <>
            <Link to="/tutor/fr/question" className="card">
              <h1>Question Générique</h1>
              <span>Posez vos questions ici.</span>
            </Link>
            <Link to="/tutor/fr/theory" className="card">
              <h1>Théorie</h1>
              <span>Vous apprend à résoudre des problèmes mathématiques, étape par étape, avec des exemples pour vous aider à comprendre.</span>
            </Link>
            <Link to="/tutor/fr/solve" className="card">
              <h1>Résolution de Problème</h1>
              <span>Bloqué sur une question? Obtenez une explication détaillée sur la méthode de résolution ici.</span>
            </Link>
            <Link to="/book/fr" className="card">
              <h1>Résumé de Livre</h1>
              <span>Besoin de vérifier si votre enfant a bien lu un livre? Ou besoin d'un résumé rapide pour un devoir de dernière minute? Ceci est pour vous!</span>
            </Link>
            <div className="card coming-soon">
              <h1>Exprimez vos souhaits</h1>
              <span>Nous sommes à l'écoute de vos commentaires et suggestions. Contactez-nous à <b>thomas@maths-tutor-ai.com</b></span>
            </div>
          </>
        )} </>
      </div>
    </div>
  );
};

export default TutorSelector;
