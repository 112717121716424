import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import GPT from "../utilities/GPT";
import { getPrompt } from "../utilities/BookData";

import Header from "../components/Header";
import LanguagePicker from "../components/LanguagePicker";

import WarningAI from "./WarningAI";
import TextFormat from "../utilities/BookFormat";


const BookComponent = ({customerData, setCustomerData, updateCollection}) => {
    const {lang} = useParams();
    const [language, setLanguage] = useState(lang);
    const [loadingButton, setLoadingButton] = useState("");
    const [answer, setAnswer] = useState([]);
    const [answerLength, setAnswerLength] = useState("medium");
    const [buttonText, setButtonText] = useState("Go");
    const [bookTitle, setBookTitle] = useState("");
    const [chapters, setChapters] = useState("");
    const [author, setAuthor] = useState("");

    const handleKeypress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        getAnswer();
      }
    };

    const answerType = (size) => {
      setAnswerLength(size);
    }

    const getLanguage = (lang) => {
      setLanguage(lang);
    }

    const getAnswer = async () => {
      if (bookTitle && author) {
        updateCollection(customerData.id, customerData.trial - 1);
        setCustomerData({id: customerData.id, email: customerData.email, access: customerData.access, subscribed: customerData.subscribed, trial: customerData.trial - 1});
        if (customerData.trial - 1 > 0 || customerData.subscribed === true || customerData.access === true) {
          setButtonText("");
          setLoadingButton("button-loader");
          const response = await GPT(getPrompt(language, answerLength, author, bookTitle, chapters));
          setLoadingButton("");
          setButtonText("Go");
          // console.log(response.data.choices[0].message.content)
          setAnswer(TextFormat(response.data.choices[0].message.content));
        } else {
          window.location.reload(true);
        }
      } else {
        if (language === "uk") {
          alert("please enter a book title and an author");
        } else {
          alert("veuillez entrer un titre de livre et un auteur");
        }
      }
    }

  return (
    <>
      <Header language={language}/>
      <div className="gpt-box">
        <div className="back-button">
          <Link to="/" className="button">
            <> {language === "uk" ? ("Back") : ("Retour")}</>
          </Link>
          <>
            {customerData.trial <= 0 ? (
              <></>
            ) : (
              <div className="trial">
                <> {language === "uk" ? ("Free Spins: ") : ("Tours Gratuit: ")} </>
                {customerData.trial - 1}
              </div>
            )}
          </>
        </div>
        <WarningAI language={language}/>
        <div className="interact-area">
          <div className="summary-types">
            <button 
              onClick={() => answerType("small")}
              className={answerLength === "small" ? "button small" : "button"}
            >{language === "uk" ? "Short" : "Court"}</button>
            <button 
              onClick={() => answerType("medium")}
              className={answerLength === "medium" ? "button small" : "button"}
            >{language === "uk" ? "By Chapter" : "Par Chapitre"}</button>
            <button 
              onClick={() => answerType("large")}
              className={answerLength === "large" ? "button small" : "button"}
            >{language === "uk" ? "In Depth" : "Détaillé"}</button>
          </div>
          <div className="book-details">
            <div className="book-property">
              <label className="book-label">{language === "uk" ? "Book Title" : "Titre du Livre"}</label>
              <input
                tabIndex={0}
                onKeyDown={handleKeypress}
                className="book-input"
                rows={1}
                maxLength="50"
                onChange={(e)=>setBookTitle(e.target.value)}>
              </input>
            </div>
            <div className="book-property">
              <label className="book-label">{language === "uk" ? "Book Author" : "Auteur du Livre"}</label>
              <input
                tabIndex={0}
                onKeyDown={handleKeypress}
                className="book-input"
                rows={1}
                maxLength="50"
                onChange={(e)=>setAuthor(e.target.value)}>
              </input>
            </div>
            <div className="book-property">
              <label className="book-label">{language === "uk" ? "Number of Chapters" : "Nombre de Chapitre"}</label>
              <input
                tabIndex={0}
                onKeyDown={handleKeypress}
                className="book-input"
                rows={1}
                maxLength="3"
                type="number"
                onChange={(e)=>setChapters(e.target.value)}>
              </input>
            </div>
          </div>
          <div className="book-info">{language === "uk"
            ? "If the correct number of chapters doesn't show, please add in the number of chapters for better accuracy"
            : "Si le nombre de chapitres n'est pas correct, veuillez ajouter le nombre de chapitres pour une meilleure précision"
          }</div>
          <div className="buttons">
            <div className="book-gpt-buttons">
              <LanguagePicker language={language} getLanguage={getLanguage}/>
              <button type="submit" onClick={getAnswer} className="button fixed-size"><span className={loadingButton}>{buttonText}</span></button>
            </div>
          </div>
        </div>
        <div className="gpt-reply">
          {answer.map((line, i) => {
            const words = line.split(" ");
            if (words[0] === "Chapter" || words[0] === "Chapitre") {
              // const num = words[1].substring(0, words[1].length - 1);
              return (
                <>
                  <h2 className="chapter">{words[0] + " " + words[1]}</h2>
                  <p className="summary">{words.slice(2).join(" ")}</p>
                </>
              );
            } else {
              return <p className="summary" key={i}>{line}</p>
            }
          })}
        </div>
      </div>
    </>
  );
};

export default BookComponent;
