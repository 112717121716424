import { Link } from "react-router-dom";
import { Typewriter } from 'react-simple-typewriter'

function Welcome({goToSignIn}) {

    return (
        <div className="welcome">
            <h1 className="typewriter">
                <Typewriter
                    words={['Welcome to Maths Tutor AI.']}
                    loop={1}
                    cursor
                    cursorStyle='|'
                    typeSpeed={100}
                />
            </h1>
          <div className="sign-in-extras">
                <Link to="/landing-page">Information</Link>
                <Link to="/pricing">Pricing</Link>
                <Link to='/signin'>Sign In</Link>
          </div>
        </div>
    );
}
  
export default Welcome;