import amber_warning from "../icons/amber-warning.png";

function WarningAI({language}) {
    return (
        <div className="warning">
          <img src={amber_warning} alt="amber_warning"/>
          <div>
            <> {language === "uk" ? (
              <>
                This is still an new AI and will be improved as time goes on. 
                So if there are any issues, please reach out to <b>thomas@maths-tutor-ai.com</b> or
                <span className="mailto" onClick={(e) => {window.location.href ='mailto:thomas@maths-tutor-ai.com';}}> click here</span> for support.
              </>
            ) : (
              <>
                Ceci est toujours une nouvelle IA et sera améliorée au fil du temps. Si vous rencontrez des problèmes, veuillez contacter <b>thomas@maths-tutor-ai.com</b>.
              </>
            )}</>
          </div>
        </div>
    );
}
  
export default WarningAI;