import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../../firebase";

import red_warning from "../../icons/red-warning.png"

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setShowError(false);
      })
      .catch((error) => {
        console.log(error);
        setShowError(true);
      });
  };

  return (
    <>
      <div className="box">
        <form onSubmit={signIn}>
          <h1>Log In to your Account</h1>
          <>{showError ? (
            <div className="error"><img src={red_warning} alt="warning"/><>Wrong email and/or password. Try again or click Forgot password to reset it.</></div>
          ) : (
            <div className="hide-error"></div>
          )}</>
          <div className="inputBox">
              <label>email</label>
              <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              />
          </div>
          <div className="inputBox">
              <label>password</label>
              <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              />
              <a href="/forgot-password" className="forgotten-passowrd-text">forgotten password?</a>
          </div>
          <button type="submit" name="sign-in" className="button">Log In</button>
        </form>
      </div>
    </>
  );
};

export default SignIn;